.wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
  font-size: 1.3em;
  text-align: center;
  margin-top: 4em;

  .wrapper__header {
    font-family: sans-serif;
    font-weight: 800;
    display: flex;
    padding: .3em;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    .wrapper__header__button {
      cursor: pointer;
      user-select: none;
      background: hotpink;
      font-weight: 200;
      border-bottom: 4px solid #dc51b5;
      border-radius: 14px;
      padding: .3em .5em;
      border: 1px solid #dc51b5;
      border-bottom: 4px solid #dc51b5;
      color: #ffffff;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    }
    .wrapper__header__close-link {
      text-underline-style: none;
      color: #000;
      font-family: sans-serif;
      font-weight: 100;
      cursor: pointer;
      user-select: none;
    }
  }

  .header-wrapper {
    z-index: 2;
    background: white;
    position: fixed;
    top: 0;
    overflow: hidden;
    width: 100%;
  }
  .wrapper__sub-header {
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    display: flex;
    font-weight: 200;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    img {
      margin-right: .5em;
      height: 1.5em;
      width: auto;
    }
    .wrapper__sub-header__muffin {
      font-weight: 800;
      color: #16374a;
      margin: 0 .5em 0 1em;
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    }
    .wrapper__sub-header__title {
     margin-left: 1em;
      // font-style: italic;
    }
  }

}
.wrapper__header__close-link {
  img {
    height: 2em;
    padding: 0.4em 0.2em 0.4em 0.5em;
    margin: auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 1.2em;
  }
}

.wrapper__header__close-link a {
  &:visited {
    color: #000;
  }
}



/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media only screen and (max-width: 480px) {

  .wrapper__sub-header {
    padding: 0.6em;
    font-size: 0.8em;
  }
  .slide__headline {
    font-size: 0.8em;
  }

  .wrapper__header__close-link {
    margin-right: .1em;
  }
}
