.Salt {


  .wrapper__header__close-link a {
    color: #16374a;
    &:visited {
      color: #16374a;
    }
  }

  .wrapper__sub-header {
   // background: var(--salt-sub-header-bg-color);
    color: black;
  }
}

.SaltCarousel {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.Salt .header-wrapper {
  z-index: 2;
  background: var(--salt-bg-color);
  position: fixed;
  top: 0;
  overflow: hidden;
  width: 100%;
  //box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}
