.link-wrapper {
  display: flex;
  text-align: center;
  font-family: sans-serif;
  flex-flow: row nowrap;
}

.link {
  margin: .5em;
  padding: 0 0.3em 1em;
  text-align: center;
  color: #000;
}
