
.welcome-body {
  color: #000;
  padding: 2em;
  height: 100vh;
  width: 100vw;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
}

.welcome-body__wrapper {
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
  justify-content: center;
  align-items: baseline;
}

.body-title {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-weight: 800;
  font-size: 4em;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}



.body-title__name {
  position: relative;
  span {
    font-size: 0.33em;
    background: #000;
    padding: .1em .3em;
    color: #fff;
    position: absolute;
    font-family: sans-serif;
    opacity: 1;
    right: 0;
    font-weight: 100;
  }
}

.body-content {
  font-size: 1.5em;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: baseline;
  margin: 2em 0 0;
  letter-spacing: 0.04em;
}

.body-actions {
  font-size: 1.5em;
  font-family:  sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 1em 0 0 auto;
  letter-spacing: 0.04em;
}

.body-content-divider {
  margin: .5em 0;
  display: flex;
}

.body-content__welcome-text {
  text-align: left;
  line-height: 1.4;
  font-size: 0.8em;
  font-style: italic;
}

.body-content__welcome-img img {
  width: 1.5em;
  height: auto;
  margin: 0 auto;
}

