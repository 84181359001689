.body-actions__action__underline {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  user-select: none;
}
.body-actions__action {
  position: relative;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: .5em 0;
  padding: 0.1em 0.5em 0.5em .1em;
  transition: background-color .1s linear;

  img {
    width: 2em;
    height: 2em;
    margin-right: 1em;
  }
  &:hover img {
    animation: img-on-hover-anim 1.5s infinite linear;
  }
}

.body-actions__action a {
  color: #000;
  &:visited {
    color: #000;
  }
}
