.grid-item {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 30vh;

  margin: 0 auto 1em;
  div {
    background-size: cover;
    width: 200px;
    height: 300px;
    background-position: center;
  }
}

.grid-item img {
  width: 100%;
}

@media only screen and (max-width: 480px) {

  .grid-item {
    width: 100%;
    div {
      width: 100%;
    }
  }
}
