:root {
    --salt-bg-color: #16374a;
    --salt-header-bg-color: #f5b228;
    --salt-sub-header-bg-color: #8144c3;
    --salt-sub-header-bg-color-2: #dc51b5;
    --salt-sub-header-bg-color-3: #de4c02;
    --salt-sub-header-bg-color-4: #7DD1C0;
    --default-bg-color: whitesmoke;
}


body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}


@keyframes img-on-hover-anim {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/

@media only screen and (max-width: 1024px)  {

}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/

@media only screen and (max-width: 667px) {


}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/

@media only screen and (max-width: 480px) {

    .welcome-body {
        font-size: 80%;
    }
    .welcome-body__wrapper {
        margin: 1em;
    }
    .body-title__name span {
        right: 1em;
    }
    .iframe-wrapper {
        height: 500px;
    }
    .slider {
        top: 5em;
        left: 0;
        width: 100%;
        height: 100vh;
        margin: 0 auto;
    }
    .wrapper__sub-header {
        padding: 1em 0.6em;
        font-size: 0.8em;
    }
    .slide__headline {
        font-size: 0.8em;
    }

    .wrapper__header__close-link {
        margin-right: .1em;
    }
}


