
.contact-wrapper__content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  z-index: 1;

  margin: 1em;
  .contact-wrapper__img {
    width: 200px;
    display: flex;
    justify-content: center;
    margin: auto;
    align-items: center;
    img {
      -webkit-transition: all 3s ease-in-out 2s;
      transition: all 3s ease-in-out 2s;
      animation: img-on-hover-anim 45s infinite linear;
      width: 200px;
      box-sizing: border-box;
    }
  }
  .contact-wrapper__img-bg {
    position: relative;
    z-index: 1;
    img {
      position: absolute;
      top: -2em;
      left: 0;
      right: 0;
      width: 100%;
      margin: auto;
      box-sizing: border-box;
    }

  }
  .contact-wrapper__email {
    margin: 1em auto 1em;
  }
}

.social-content {
  margin-top: 1em;
  font-family: sans-serif;
  letter-spacing: 0.04em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  .social-content__title {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 1.2em;
    &:before {
      content: '... ';
    }
    &:after {
      content: ':';
    }

  }
  .social-content__social-links {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
  }
  .social-content__kirikia {
   .social-content__kirikia__content {
      font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
      font-size: 1.2em;
    }
    margin-top: 1em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}

.contact-wrapper__header__close-link a {
  &:visited {
    color: #000;
  }
}

.Contact .wrapper {
  max-width: 800px;
}
