@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap');

:root {
  --base-duration: 600ms;
  --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
}

  .visuallyhidden {
    clip: rect(1px, 1px, 1px, 1px);

    height: 1px;
    overflow: hidden;
    position: absolute !important;
    white-space: nowrap;
    width: 1px;
  }


  // =========================
  // Icons
  // =========================

  .icon {
    fill: var(--color-primary);
    width: 100%;
  }


  // =========================
  // Buttons
  // =========================

  .btn {
    background-color: var(--color-primary);
    border: none;
    border-radius: 0.125rem;
    color: white;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
    padding: 1rem 2.5rem 1.125rem;

    &:focus {
      outline-color: var(--color-focus);
      outline-offset: 2px;
      outline-style: solid;
      outline-width: 3px;
    }

    &:active {
      transform: translateY(1px);
    }
  }


  // =========================
  // Slider controls
  // =========================

  .slider__controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(50% + 1rem);

    transform: rotate(-90deg);
      .btn {
      --size: 3rem;

      align-items: center;
      background-color: transparent;
      border: 3px solid transparent;
      border-radius: 100%;
      display: flex;
      height: var(--size);
      padding: 0;
      width: var(--size);

      &:focus {
        border-color: var(--color-focus);
        outline: none;
      }

      &--previous > * {
        transform: rotate(180deg);
      }
    }
  }


  // =========================
  // Slider
  // =========================

  .slider {

    z-index: -1;
    --slide-size: 70vmin;
    --slide-margin: 4vmin;

    height: var(--slide-size);
    margin: 0 auto;
    width: var(--slide-size);

    position: absolute;
    bottom: 0;
    top: 4em;
    left: 0;
    right: 0;
  }

  .slider__wrapper {
    display: flex;
    padding: 0;
    margin: 0 calc(var(--slide-margin) * -1);
    position: absolute;
    transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
  }


  // =========================
  // Slide
  // =========================

  .slide {
    align-items: center;
    color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: var(--slide-size);
    justify-content: center;
    margin: var(--slide-margin);
    opacity: 0.25;
    position: relative;
    text-align: center;
    transition:
            opacity calc(var(--base-duration) / 2) var(--base-ease),
            transform calc(var(--base-duration) / 2) var(--base-ease);
    width: var(--slide-size);
    z-index: 1;

    &--previous,
    &--next {
      &:hover {
        opacity: 0.5;
      }
    }

    &--previous {
      cursor: n-resize;

      &:hover {
        transform: translateX(2%);
      }
    }

    &--next {
      cursor: s-resize;

      &:hover {
        transform: translateX(-2%);
      }
    }
  }

  .slide--current {
    --x: 0;
    --y: 0;
    --d: 50;

    opacity: 1;
    pointer-events: auto;
    user-select: auto;

    @media (hover: hover) {
      &:hover .slide__image-wrapper {
        transform:
                scale(1.025)
                translate(
                                calc(var(--x) / var(--d) * 1px),
                                calc(var(--y) / var(--d) * 1px)
                );
      }
    }
  }

  .slide__image-wrapper {
    background-color: var(--color-accent);
    border-radius: 1%;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: transform calc(var(--base-duration) / 4) var(--base-ease);
    width: 100%;
  }

  .slide__image {
    --d: 20;

    height: 110%;
    left: -5%;
    object-fit: contain;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -5%;
    transition:
            opacity var(--base-duration) var(--base-ease),
            transform var(--base-duration) var(--base-ease);
    user-select: none;
    width: 110%;

    @media (hover: hover) {
      .slide--current & {
        transform:
                translate(
                                calc(var(--x) / var(--d) * 1px),
                                calc(var(--y) / var(--d) * 1px)
                );
      }
    }
  }

  .slide__headline {
    font-weight: 100;
    color: #fff;
    background-color: #232323;
    position: relative;
    bottom: -80%;
    padding: .1em 0.3em 0.2em;
  }

  .slide__content {
    --d: 60;

    width: auto;
    height: 80%;
    padding: 1em;
    opacity: 0;
    position: relative;
    transition: transform var(--base-duration) var(--base-ease);
    visibility: hidden;

    .slide--current & {
      animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
      visibility: visible;

      @media (hover: hover) {
        transform:
                translate(
                                calc(var(--x) / var(--d) * -1px),
                                calc(var(--y) / var(--d) * -1px)
                );
      }
    }

    > * + * {
      margin-top: 2rem;
    }
  }


  // =========================
  // Animations
  // =========================

  @keyframes fade-in {
    from { opacity: 0 }
    to   { opacity: 1 }
  }

